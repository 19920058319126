//
// _variables
//

// Typography
$font-family-prose: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$font-family-title: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$webfont-font-family-prose: Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$webfont-font-family-title: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$font-family-base: $font-family-prose;
$font-weight-bolder: 900;
$line-height-base: 2.125;

// Colours
$gray-700: #4A4A4A;
$body-color: $gray-700;
$theme-colors: (
  'brand': #67b806,
  'black': #000,
  'white': #fff,
  'light': #F0F0F0,
  'gray':  #9B9B9B,
);
