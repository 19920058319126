@import "_variables";
@import "../node_modules/bootstrap/scss/bootstrap";

@import "typography";
@import "base_style";

#work-page,
#blogs-page {
  @import "work_style";
}
#services-page {
  @import 'services_style';
}
#contact-page {
  @import  'contact_style';
}
#culture-page {
  @import 'culture_style';
}
#team-page {
  @import 'team_style';
}
#case-study {
  @import 'case_study_style';
}

.richtext {
  ul,
  p {
    @extend .how-we-description;
  }
  h3 {
    @extend .funded;
  }
}

#home {

  @extend .caption-2;

  h2 {
    @extend .caption-1;
  }
  h1 {
    @extend .caption;
    font-size: $h1-font-size;
    @include media-breakpoint-up(sm) {
      font-size: 1.2 * $h1-font-size;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.4 * $h1-font-size;
    }
  }
  h3 {
    @extend .caption-2;
  }
}
.team-description {
  p {
    @extend .how-we-description, .text-justify;
  }
  p:first-of-type {
    @extend .pt-5;
  }
}

.two-column {
  column-count: 2;

  ul {
    break-inside: avoid;
  }
}

.list-group-item {
  p {
    margin-bottom: 0.25rem;
  }
}

.min-h-300p {
  min-height: 300px;
}
.max-h-150p {
  max-height: 150px;
}
.max-w-100p {
  max-width: 100px;
}
.max-h-100p {
  max-height: 100px;
}
@include media-breakpoint-up(sm) {
  .max-w-60p-sm {
    max-width: 60px;
  }
  .max-h-60p-sm {
    max-height: 60px;
  }
}
